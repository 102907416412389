import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";

import Input from "src/components/Input";
import Select from "src/components/Select";
import Range from "src/components/Range";
import Card from "src/components/Card";
import CheckBox from "src/components/CheckBox";
import { ReactComponent as UploadIcon } from "src/assets/img/upload.svg";
import { AccountInformationInterface } from "src/types/registration";

import { businessType } from "src/types/businessType";
import { SelectorListObj } from "src/components/Select/types";
import { stampIcon } from "src/types/stampIcon";
import FormError from "../../../../components/FormError/FormError";
import {
  registerYourCompany,
  getBusinessTypesAxios,
  getStampIconsAxios, getActivateDaysAxios
} from "./api";
import * as Const from "../../consts";
import { StampIconAdditionalData } from "./types";
import { DaysWrapperDiv, Wrapper, SpanInputText } from "./styled";

const RegistrationBuildYourCard: React.FC<{
  accountInformationToken: string | null;
  goToStep: (step: number) => void;
}> = ({ accountInformationToken, goToStep }) => {
  const [formErrorMessage, setFormErrorMessage] = React.useState<any>({});
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [accountInformation, setAccountInformation] =
    useState<AccountInformationInterface>({
      companyName: "",
      businessType: "",
      stampIcon: "",
      promotionType: 1,
      stampName: "",
      rewardInformation: "",
      rewardCode: "",
      maxNumberPromotion: 9,
      promotionLabel: "",
      first_checkIn: 0,
      rewards: [
        {
          pointsAmount: 0,
          reward: "",
          rewardNumber: 0,
          rewardDetails: "",
          dateExpire: 0,
          activateAfter: 0
        },
        // {
        //   pointsAmount: 0,
        //   reward: "",
        //   rewardNumber: 0,
        //   rewardDetails: "",
        //   dateExpire: 0,
        //   activateAfter: 0
        // },
        // {
        //   pointsAmount: 0,
        //   reward: "",
        //   rewardNumber: 0,
        //   rewardDetails: "",
        //   dateExpire: 0,
        //   activateAfter: 0
        // },
        // {
        //   pointsAmount: 0,
        //   reward: "",
        //   rewardNumber: 0,
        //   rewardDetails: "",
        //   dateExpire: 0,
        //   activateAfter: 0
        // },
        // {
        //   pointsAmount: 0,
        //   reward: "",
        //   rewardNumber: 0,
        //   rewardDetails: "",
        //   dateExpire: 0,
        //   activateAfter: 0
        // }
      ]
    });

  const [defValueForNumPromotion, setDefValueForNumPromotion] = useState(
    Const.stampList[4]
  );

  const [openExpireDateReward, setOpenExpireDateReward] = useState<number[]>(
    []
  );

  // const {
  //   promotionCategories,
  //   promotionCategoriesEnable,
  //   // promotionTypes,
  //   // promotionTypesEnable,
  // } = useTypedSelector((state) => state.promotion);

  const getValue = (type: string, value: number | string) => {
    setFormErrorMessage({});
    setAccountInformation({ ...accountInformation, [type]: value });
  };

  // const onDrop = (acceptedFiles: any) => {
  const onDrop = () => {
    // setAdditionalInformation({
    //   ...additionalInformation,
    //   logo: acceptedFiles[0],
    // });
  };

  const { acceptedFiles, isDragActive, getRootProps, getInputProps } =
    useDropzone({
      accept: ["image/jpeg", "image/jpg", "image/png"],
      multiple: false,
      onDrop,
    });

  const handleAccountInformation = (event: React.FormEvent) => {
    event.preventDefault();
    if (accountInformationToken !== null) {
      setIsLoading(true);
      registerYourCompany(
        accountInformation,
        accountInformationToken,
        acceptedFiles
      )
        .then(() => {
          goToStep(8);
          setIsLoading(false);
        })
        .catch((error) => {
          setFormErrorMessage(error.data.errors);
          setIsLoading(false);
        });
    }
    // setAdditionalInformationRegistration(additionalInformation);
  };

  const [businessTypeList, setBusinessTypeList] = useState<Array<SelectorListObj>>([]);

  const [stampIconList, setStampIconList] = useState<Array<SelectorListObj>>(
    []
  );

  const [activateDaysList, setActivateDaysList] = useState<Array<SelectorListObj>>(
    []
  );

  const [cardClosed, setCardClosed] = useState<boolean>(true);

  useEffect(() => {
    getBusinessTypesAxios().then((response) => {
      setBusinessTypeList(
        response.data.businessTypes.map((_businessType: businessType) => {
          return {
            value: _businessType.id,
            label: _businessType.display_name,
          };
        })
      );
    });

    getActivateDaysAxios().then((response) => {
      setActivateDaysList(response.data.activateDays);
    });

    getStampIconsAxios().then((response) => {
      setStampIconList(
        response.data.stampIcons.map((_stampIcon: stampIcon) => {
          return {
            value: _stampIcon.id,
            label: _stampIcon.display_name,
            additional: {
              icon_name: _stampIcon.name,
              single_item: _stampIcon.single_item,
            },
          };
        })
      );
    });
  }, []);

  const setInputValue = (value: string, index: number) => {
    const newInfo = {
      ...accountInformation
    };

    if (newInfo.rewards) {
      newInfo.rewards[index].dateExpire = +value;
      setAccountInformation(newInfo);
    }
  }

  return (
    <Wrapper>
      <div className="title-block">
        <div className="title">Lets Build Your Card</div>
        <div className="sub-title">
          Complete the fields below to build a loyalty card for your business.
        </div>
      </div>
      <div className={`build_card_wrapper ${isLoading ? "loading-blue" : ""}`}>
        <div className="build_card_left">
          <form className="lynked-form" onSubmit={handleAccountInformation}>
            <div className="form-innerwrap">
              <div className="form-group">
                <div className="dropZone">
                  <div {...getRootProps()} className={`active_${isDragActive}`} > {/*eslint-disable-line*/}
                    <input {...getInputProps()} required /> {/*eslint-disable-line*/}
                    <div>
                      <UploadIcon />
                      {acceptedFiles.length ? (
                        <span className="text">
                          <span className="file-name">
                            {acceptedFiles[0].name}
                          </span>
                        </span>
                      ) : (
                        <span className="text">
                          <span className="title">
                            Please upload your logo we want to personalize your
                            loyalty card
                          </span>
                          <span className="description">
                            (logo should be 1080 x 1080px)
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <FormError formErrorMessages={formErrorMessage} field="image" />
              </div>
              <div className="form-group">
                <Input
                  label="Confirm Company Name"
                  placeholder="Company Name"
                  infoTooltip="This will identify your venue on the Lynked in-app map. Enter the venue brand name here, not the company formation name."
                  required
                  getValue={(value) => getValue("companyName", value)}
                  error={formErrorMessage.name}
                />
                {/* <FormError formErrorMessages={formErrorMessage} field="name" /> */}
              </div>
              {accountInformation.promotionType === 2 ?
                <div className="form-group promotionName-group">
                  <Input
                    placeholder="First Check-In Present"
                    label="First Time Check-In Points"
                    required
                    maxLength={28}
                    min={0}
                    type={'number'}
                    defaultValue={accountInformation.first_checkIn ? accountInformation.first_checkIn : '0' }
                    getValue={(value) =>
                      value.trim() ? getValue("first_checkIn", value.trim()) : getValue("first_checkIn", '0')
                    }
                  />
                </div> : null}
              <div className="form-group">
                <Select
                  label="Business Type"
                  placeholder="Select Business Type"
                  selectorList={businessTypeList}
                  positionForShow="bottom"
                  required
                  getValue={(value) => getValue("businessType", value)}
                  hideSelectorFilter
                />
                <FormError
                  formErrorMessages={formErrorMessage}
                  field="business_type"
                />
              </div>
              <p className="switch_label">Please select Stamps or Points</p>
              <div className="form-group">
                <div
                  className={`control_switch ${
                    accountInformation.promotionType === 2
                      ? "control_switch_right"
                      : ""
                  }`}
                >
                  <button
                    type="button"
                    className={`btn_switch ${
                      accountInformation.promotionType === 1
                        ? "btn_switch_active"
                        : ""
                    }`}
                    onClick={() =>
                      setAccountInformation({
                        ...accountInformation,
                        promotionType: 1,
                      })
                    }
                  >
                    Stamps
                  </button>
                  <button
                    type="button"
                    className={`btn_switch ${
                      accountInformation.promotionType === 2
                        ? "btn_switch_active"
                        : ""
                    }`}
                    onClick={() =>
                      setAccountInformation({
                        ...accountInformation,
                        promotionType: 2,
                      })
                    }
                  >
                    Points
                  </button>
                </div>
                <FormError
                  formErrorMessages={formErrorMessage}
                  field="promotion_type"
                />
              </div>
              {accountInformation.promotionType === 1 ? (
                <>
                  <div className="form-group">
                    <Select
                      label="Select Stamp Icon Most Suited to Your Business"
                      placeholder="Select Stamp Icon"
                      infoTooltip="This stamp will appear on your loyalty card for your customers, you can see a preview on the digital card builder on the right of the screen."
                      selectorList={stampIconList}
                      positionForShow="bottom"
                      required
                      getValue={(value, label, additional) => {
                        const stampIconData: StampIconAdditionalData =
                          additional as StampIconAdditionalData;
                        const accountInformationState: AccountInformationInterface =
                          {
                            ...accountInformation,
                          };

                        accountInformationState.stampIcon = value;
                        accountInformationState.stampName =
                          stampIconData.icon_name;

                        setAccountInformation(accountInformationState);
                      }}
                      hideSelectorFilter
                    />
                    <FormError
                      formErrorMessages={formErrorMessage}
                      field="stamp_icon"
                    />
                  </div>
                  <p className="label">Select Loyalty Rewards Level</p>
                  <div className="form-group form-group_two">
                    <div className="range_wrapper">
                      <Range
                        minValue={5}
                        maxValue={10}
                        value={accountInformation.maxNumberPromotion}
                        onChange={(value) => {
                          const curValue = Const.stampList.find(
                            (stamp) => stamp.value === value
                          );
                          setDefValueForNumPromotion(
                            curValue || Const.stampList[0]
                          );
                          getValue("maxNumberPromotion", value);
                        }}
                      />
                    </div>
                    <span className="or">or</span>
                    <div className="input_wrapper">
                      <Select
                        infoTooltip={`A sample promotion if you are a coffee shop: Spend over €15 and receive a free donut. This can be any promotion that you can offer any customer that may visit you once, when passing through or visit you every day so keep it open!`}
                        selectorList={Const.stampList}
                        positionForShow="top"
                        required
                        defaultValue={defValueForNumPromotion}
                        getValue={(value) =>
                          getValue("maxNumberPromotion", value)
                        }
                        hideSelectorFilter
                      />
                    </div>
                    <FormError
                      formErrorMessages={formErrorMessage}
                      field="number_promotions"
                    />
                  </div>
                  {/* <div className="form-group ">
                <InputGoogleAutocomplete
                  placeholder="Please enter your first location"
                  infoTooltip="A sample promotion if you are a coffee shop: Spend over €15 and receive a free donut. This can be any promotion that you can offer any customer that may visit you once, when passing through or visit you every day so keep it open!"
                  required
                  getValue={(value) => getValue("address", value)}
                  getLat={(value) => getValue("latitude", value)}
                  getLng={(value) => getValue("longitude", value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="terms" className="checkbox blue-checkbox">
                  <span className="checkbox-input-wrap">
                    <input type="checkbox" id="terms" required />
                    <span className="checkbox-view" />
                  </span>
                  <div className="text">
                    Confirm you agree to Lynked&apos;s
                    <a href="/" rel="noreferrer" target="_blank">
                      Terms of Business
                    </a>
                  </div>
                </label>
              </div> */}
                  {/* <div className="form-group">
                    <Input
                      label="Digital Loyalty Program"
                      infoTooltip="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                      getValue={(value) => getValue("promotionLabel", value)}
                      error={formErrorMessage.promotion_label}
                    />
                    <FormError
                      formErrorMessages={formErrorMessage}
                      field="promotion_label"
                    />
                  </div> */}
                  <div className="form-group">
                    <Input
                      label="Details"
                      infoTooltip="Enter the details of your loyalty program here, i.e. “Collect 10 stamps to receive a free coffee."
                      getValue={(value) => getValue("rewardInformation", value)}
                      error={formErrorMessage.details}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="form-group">
                    <Input
                      label="Details"
                      // infoTooltip="Enter the details of your loyalty program here, i.e. “Collect 10 stamps to receive a free coffee."
                      getValue={(value) => getValue("rewardInformation", value)}
                      error={formErrorMessage.details}
                    />
                  </div>
                  <p className="form_title">Let&apos;s add your reward</p>
                  <p className="form_sub_title">
                    Please enter your points and reward for each milestone
                    below.
                    <br /> Please remember €1 = 1 point
                  </p>
                  {accountInformation.rewards?.map(
                    (
                      { rewardDetails, reward, dateExpire, rewardNumber , pointsAmount },
                      i
                    ) => (<div key={i} className="reward_block">
                        <div className="reward_label_wrapper">
                          <div className="reward_label">{`Reward`}</div>
                          {/*{i ? (*/}
                          {/*  <CheckBox*/}
                          {/*    label="Use previous reward criteria"*/}
                          {/*    isChecked={*/}
                          {/*      accountInformation.rewards*/}
                          {/*        ? (pointObjValuesToString(*/}
                          {/*            accountInformation.rewards[i - 1]*/}
                          {/*          ) ===*/}
                          {/*          pointObjValuesToString(*/}
                          {/*            accountInformation.rewards[i]*/}
                          {/*          ))*/}
                          {/*        : false*/}
                          {/*    }*/}
                          {/*    onChange={(isChecked) => {*/}
                          {/*      const newInfo = {*/}
                          {/*        ...accountInformation*/}
                          {/*      };*/}
                          {/*      */}
                          {/*      if (isChecked) {*/}
                          {/*        if (newInfo.rewards) {*/}
                          {/*          */}
                          {/*          newInfo.rewards[i] = structuredClone(newInfo.rewards[i - 1]);*/}
                          {/*          */}
                          {/*          setAccountInformation(newInfo);*/}
                          {/*        }*/}
                          {/*      } else {*/}
                          {/*        if (newInfo.rewards) {*/}
                          {/*          newInfo.rewards[i] = {*/}
                          {/*            ...newInfo.rewards[i],*/}
                          {/*            pointsAmount: 0,*/}
                          {/*            reward: "",*/}
                          {/*            rewardNumber: 0,*/}
                          {/*            rewardDetails: "",*/}
                          {/*            dateExpire: 0,*/}
                          {/*            activateAfter: 0*/}
                          {/*          };*/}
                          {/*        }*/}
                          {/*        setAccountInformation(newInfo);*/}
                          {/*      }*/}
                          {/*    }}*/}
                          {/*  />*/}
                          {/*) : null}*/}
                        </div>
                        <div className="reward_inputs">
                          <Input
                            required
                            type="number"
                            label="Points amount"
                            infoTooltip={`When a customer receives this number of points they will be entitled to a reward. Note. Lynked points are accumulated as €1 = 1 point. Keep in mind your typical transaction values related to your business when setting your points limit. This can be changed in your dashboard. You can increase the points amount from reward 1-5 to entice customers to spend more and reach the next reward level.`}
                            getValue={(value) => {
                              const newInfo = {...accountInformation};
                              if (newInfo.rewards) {
                                newInfo.rewards[i].pointsAmount = +value;
                                setAccountInformation(newInfo);
                              }
                            }}
                            error={
                              formErrorMessage?.points
                                ? formErrorMessage?.points[i].pointsAmount
                                : null
                            }
                            defaultValue={pointsAmount}
                          />
                          <Input
                            required
                            label="Reward Number"
                            infoTooltip={`Enter a number value only if you are integrating your e-commerce store, e.g. “5“ this represents a “€5 discount“.`}
                            getValue={(value) => {
                              const newInfo = {...accountInformation};
                              if (newInfo.rewards) {
                                newInfo.rewards[i].rewardNumber = value;
                                setAccountInformation(newInfo);
                              }
                            }}
                            defaultValue={`${rewardNumber}`}
                            error={
                              formErrorMessage?.points
                                ? formErrorMessage?.points[i].rewardNumber
                                : null
                            }
                            type={'number'}
                          />
                          <Input
                            required
                            label="Reward Label"
                            // infoTooltip="Enter a number value only if you are integrating your e-commerce store, e.g. “5“ this represents a “€5 discount“."
                            getValue={(value) => {
                              const newInfo = {...accountInformation};
                              if (newInfo.rewards) {
                                newInfo.rewards[i].reward = value;
                                setAccountInformation(newInfo);
                              }
                            }}
                            defaultValue={reward}
                          />
                          <Input
                            required
                            label="Reward Details"
                            infoTooltip={`Enter the details of your loyalty program here, i.e. “Collect 100 points to receive a €5 discount.`}
                            getValue={(value) => {
                              const newInfo = {...accountInformation};
                              if (newInfo.rewards) {
                                newInfo.rewards[i].rewardDetails = value;
                                setAccountInformation(newInfo);
                              }
                            }}
                            defaultValue={rewardDetails}
                            error={
                              formErrorMessage?.points
                                ? formErrorMessage?.points[i].rewardDetails
                                : null
                            }
                          />
                          <Select
                            selectorList={activateDaysList}
                            positionForShow="bottom"
                            label="Voucher Activation"
                            defaultValue={activateDaysList[accountInformation?.rewards?.[i].activateAfter ? accountInformation.rewards[i].activateAfter - 1 : 0]}
                            required
                            hideSelectorFilter
                            getValue={(value) => {
                              const newInfo = {...accountInformation};
                              
                              if (newInfo.rewards) {
                                newInfo.rewards[i].activateAfter = +value;
                                setAccountInformation(newInfo);
                              }
                            }}
                          />
                          <div className="reward_expired">
                            <CheckBox
                              label="Expires After"
                              isChecked={
                                openExpireDateReward.includes(i) || !!dateExpire
                              }
                              onChange={(isChecked) => {
                                setOpenExpireDateReward((currValues) => {
                                  if (isChecked) {
                                    if (currValues.includes(i)) {
                                      return currValues;
                                    }
                                    const newInfo = {...accountInformation};
                                    if (newInfo.rewards) {
                                      newInfo.rewards[i].dateExpire = 30;
                                      setAccountInformation(newInfo);
                                    }
                                    return [...currValues, i];
                                  }
                                  const newInfo = {...accountInformation};
                                  if (newInfo.rewards) {
                                    newInfo.rewards[i].dateExpire = 0;
                                    setAccountInformation(newInfo);
                                  }
                                  return currValues.filter((key) => key !== i);
                                });
                              }}
                            />
                            {openExpireDateReward.includes(i) ||
                            !!dateExpire ? (
                              // <Select
                              //   selectorList={Const.EXPIRE_REWARD_LIST}
                              //   placeholder="Select Expire Date"
                              //   positionForShow="bottom"
                              //   defaultValue={{
                              //     value: accountInformation.rewards
                              //       ? accountInformation.rewards[i].dateExpire
                              //       : "",
                              //     label: accountInformation.rewards
                              //       ? accountInformation?.rewards[
                              //           i
                              //         ].dateExpire.toString()
                              //       : "",
                              //   }}
                              //   required
                              //   getValue={(value) => {
                              //     const newInfo = { ...accountInformation };
                              //     if (newInfo.rewards) {
                              //       newInfo.rewards[i].dateExpire = +value;
                              //       setAccountInformation(newInfo);
                              //     }
                              //   }}
                              //   hideSelectorFilter
                              // />
                              <DaysWrapperDiv>
                                <Input
                                  type="number"
                                  placeholder="Enter Expire Date"
                                  required
                                  defaultValue={dateExpire}
                                  infoTooltip="If you would like rewards earned by your customers to expire after a certain number of days tick this box. Please enter the number of days between earning the reward, and reward expiry, here."
                                  getValue={(value) => setInputValue(value, i)}
                                />
                                {accountInformation?.rewards?.[i].dateExpire ? <SpanInputText>days</SpanInputText> : null}
                              </DaysWrapperDiv>
                            
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )
                    )}
                </>
              )}
              <div className="form-group">
                <label htmlFor="terms" className="checkbox blue-checkbox">
                  <span className="checkbox-input-wrap">
                    <input type="checkbox" id="terms" required/>
                    <span className="checkbox-view"/>
                  </span>
                  <div className="text">
                    I agree to
                    <span> </span>
                    <a
                      href="https://lynkedloyalty.com/terms-and-conditions/"
                      rel="noreferrer"
                      target="_blank"
                      className="checkbox_links"
                    >
                      Terms of Business
                    </a>
                    <span> </span>
                    and
                    <span> </span>
                    <a
                      href="https://lynkedloyalty.com/privacy-policy/"
                      rel="noreferrer"
                      target="_blank"
                      className="checkbox_links"
                    >
                      Privacy Policy
                    </a>
                  </div>
                </label>
              </div>
              <FormError formErrorMessages={formErrorMessage} field="token"/>
              <button type="submit" className="btn-blue">
                Continue
              </button>
            </div>
          </form>
        </div>
        <div className="build_card_right">
          <p className="switch_label">
            What your card looks like to customers...
          </p>
          <Card
            acceptedFiles={acceptedFiles}
            accountInformation={accountInformation}
            cardClosed={cardClosed}
            setCardClosed={setCardClosed}
            points_info={{
              reward: accountInformation.rewards
                ? accountInformation.rewards[0].reward
                : "0",
              points: accountInformation.rewards
                ? accountInformation.rewards[0].pointsAmount
                : "0",
              maxPoints: accountInformation.rewards
                ? accountInformation.rewards[0].pointsAmount
                : "0",
            }}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default RegistrationBuildYourCard;
